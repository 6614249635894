// @ts-nocheck

import Vue from 'vue'
import App from './App.vue'
import router from "@/router";

Vue.config.productionTip = false

import { Field, Taginput, Checkbox } from 'buefy'

Vue.use(Field)
Vue.use(Taginput)
Vue.use(Checkbox)

import Appsignal from "@appsignal/javascript"
import { errorHandler } from "@appsignal/vue";
import { plugin } from '@appsignal/plugin-window-events'


if (process.env.NODE_ENV != 'development'){
  const appsignal = new Appsignal({ key: "f32a8e21-c2a2-44e5-ad34-60b224bf7d59", revision: process.env.VUE_APP_CF_PAGES_COMMIT_SHA });
  appsignal.use(plugin({ onerror: true, onunhandledrejection: true }));

  Vue.config.errorHandler = errorHandler(appsignal, Vue);
}

new Vue({
  router: router,
  render: h => h(App),
}).$mount('#app')
