<template lang="pug">
  div
    nav.navbar
      .navbar-brand
        .navbar-item.has-dropdown(:class="{ 'is-active': uploadRegionSelect }")
          a.navbar-link(@click.preventDefault="uploadRegionSelect = !uploadRegionSelect")
            | upload region: {{ uploadRegion }}

          .navbar-dropdown
            a.navbar-item(v-for="(region, index) in regions" :key="index" @click.preventDefault="setUploadRegion(region)" :class="{ 'is-active': region == uploadRegion}")
              | {{ region }}

      .navbar-end
        .navbar-item
          .buttons
            button.button(@click="connectWallet" v-if="!wallet.connected") Connect Wallet
            button.button.is-success.is-disabled(v-if="wallet.connected") Connected

    section.section
      .container
        transition(name="fade" mode="out-in")
          router-view(:key="mint" :tezos="tezos" :collections="collections" :wallet="wallet" :uploadRegion="uploadRegion" @newMint="newMint")
</template>

<style lang="sass">
@import "minireset.css/minireset.sass"
@import "bulma/bulma.sass"
@import 'buefy/dist/buefy.css'
</style>

<script>
import {MichelCodecPacker, TezosToolkit} from '@taquito/taquito';
import { BeaconWallet } from '@taquito/beacon-wallet';
import axios from 'axios'

const UPLOAD_REGIONS = process.env.NODE_ENV == 'development' ? ["my computer"] : ["america", "asia", "europe"]
const DEFAULT_REGION = process.env.NODE_ENV == 'development' ? "my computer" : "america"

export default {
  name: "App",
  data() {
    return {
      wallet: {
        connected: false,
        address: ""
      },
      collections: [],
      tezos: null,
      rpcNodes: [],
      uploadRegion: DEFAULT_REGION,
      uploadRegionSelect: false,
      regions: UPLOAD_REGIONS,
      // 💡 Little trick to reload component by updating this attribute on pressing
      // `Mint again` in form - @awea 20220725
      mint: 0
    };
  },
  mounted(){
    axios({
      url: 'https://back.objkt.com/api/v1/rpc/nodes.json',
      method: 'get'
    }).then(({data}) => {
      this.rpcNodes = data
    });
  },
  methods: {
    async connectWallet(){
      const options = {
        name: 'objkt.com',
        iconUrl: 'https://assets.objkt.media/file/assets-002/objkt/objkt-logo.png',
      };
      const wallet = new BeaconWallet(options);

      // The Beacon wallet requires an extra step to set up the network to connect to and the permissions:
      await wallet.requestPermissions({
        network: {
          type: 'mainnet'
        },
      });

      const tezos = new TezosToolkit(this.rpcNodes[Math.floor(Math.random() * this.rpcNodes.length)]);

      tezos.setPackerProvider(new MichelCodecPacker())
      tezos.setWalletProvider(wallet);

      this.tezos = tezos

      this.wallet.address = await wallet.getPKH()
      this.wallet.connected = true
    },
    setUploadRegion(region){
      this.uploadRegion = region
      this.uploadRegionSelect = false
    },
    newMint(){
      this.mint += 1
    }
  },
  watch: {
    // whenever question changes, this function will run
    'wallet.address'(address) {
      axios({
        url: 'https://api2.objkt.com/v1/graphql',
        method: 'post',
        data: {
          query: `
            query getOnlyUserCollections($address: String!) {
              fa(where: {_or: [
                  {creator_address: {_eq: $address}},
                  {collaborators: {collaborator_address: {_eq: $address}}}]}) {
                name
                contract
                collection_id
                collaborators {
                  holder {
                    alias
                    address
                  }
                }
              }
            }
            `,
          variables: {
            address: address
          }
        }
      }).then((response) => {
        this.collections = response.data.data.fa
      });
    }
  }
};
</script>
