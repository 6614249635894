import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/pages"),
    props: true
  }
];

export default new Router({
  routes // short for `routes: routes`,
});
